import classnames from 'classnames';

import styles from './index.module.css';

export interface StaticIntroProp {
    className?: string;
}

const StaticIntro: React.FC<StaticIntroProp> = ({ className }) => (
    <div className={classnames(styles.staticIntro, className)}>
        <div className={styles.text}>
            這是一座萬物共「聲」的奇妙島嶼，在這裡，大地的呼吸、萬物的吟唱、人們的歌謠，共同構成了這座「活」的聲音博物館，述說著時代的故事、歷史的秘密。
            <br />
            <br />
            踏上島嶼後，請打開「耳朵」認識不同年代的恆春風華，用手機解任務、集圖鑑，用聲音突破年代的桎梏、跳脫人與人之間減少對話的高牆。
        </div>
    </div>
);

export default StaticIntro;
