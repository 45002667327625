export type PageType = 'about' | 'route' | 'howto' | 'purchase' | 'faq' | 'contact' | 'team';

const pages: { key: PageType; label: string }[] = [
    {
        key: 'about',
        label: '關於共聲島',
    },
    {
        key: 'route',
        label: '路線介紹',
    },
    {
        key: 'howto',
        label: '怎麼玩',
    },
    {
        key: 'purchase',
        label: '購買流程',
    },
    {
        key: 'faq',
        label: 'FAQ',
    },
    {
        key: 'contact',
        label: '聯絡我們',
    },
    {
        key: 'team',
        label: '團隊介紹',
    },
];

export default pages;
