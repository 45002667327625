'use client';

import classnames from 'classnames';
import { useRef } from 'react';
import { gsap } from 'gsap';
import { useGSAP } from '@gsap/react';

import styles from './index.module.css';

export interface RocketProp {
    className?: string;
    moving?: boolean;
}

const srcWidth = 150;
const srcHeight = 330;
const scale = 0.25;

const Rocket: React.FC<RocketProp> = ({ className, moving }) => {
    const rocketRef = useRef<HTMLDivElement | null>(null);

    useGSAP(
        () => {
            const timeline = gsap.timeline({
                paused: true,
                repeat: -1,
                repeatDelay: 1,
            });
            timeline
                .fromTo(rocketRef.current, { x: 0 }, { x: '-10%' })
                .to(rocketRef.current, { x: '10%' })
                .to(rocketRef.current, { x: 0 });

            if (moving) {
                timeline.play();
            }
        },
        {
            dependencies: [moving],
            revertOnUpdate: true,
        },
    );

    return (
        <div className={classnames(styles.rocket, className)}>
            <div ref={rocketRef}>
                <img
                    src="/images/rocket.png"
                    alt="rocket"
                    width={srcWidth * scale}
                    height={srcHeight * scale}
                />
            </div>
        </div>
    );
};

export default Rocket;
