import classnames from 'classnames';
import { useCallback, useState } from 'react';
import { createBreakpoint } from 'react-use';
import { animated, useSpring, useScroll, config } from '@react-spring/web';
import { useWindowSize } from '@/utils/hooks';
import { map } from '@/utils/math';

import CountdownMainSection from '@/components/molecules/CountdownMainSection';
import Enter from '@/components/molecules/Enter';
import EnterStatic from '@/components/molecules/EnterStatic';

import styles from './index.module.css';

export interface IntroProp {
    className?: string;
    countdown?: boolean;
    staticMode?: boolean;
    onStarProgressUpdate?: (progress: number) => void;
    onLightProgressUpdate?: (progress: number) => void;
    onEnter?: () => void;
}

const useBreakpoint = createBreakpoint();

const sectionNum = 6;

const firstSectionStart = 15;
const secondSectionStart = 35;
const secondSectionEnd = 55;
const lastSectionStart = 90;

const LightStart = 70;

const Intro: React.FC<IntroProp> = ({
    className,
    countdown,
    staticMode,
    onStarProgressUpdate = () => {},
    onLightProgressUpdate = () => {},
    onEnter = () => {},
}) => {
    const { winHeight, winWidth } = useWindowSize();
    const breakpoint = useBreakpoint();
    const [mainSectionActive, setMainSectionActive] = useState(false);

    const [section1Styles, section1Api] = useSpring(() => ({
        opacity: 0,
        y: 10,
        config: config.slow,
    }));

    const [section2Styles, section2Api] = useSpring(() => ({
        opacity: 0,
        y: 10,
        config: config.slow,
    }));

    const [section3Styles, section3Api] = useSpring(() => ({
        opacity: 0,
        y: 10,
        config: config.slow,
    }));

    useScroll({
        onChange(result) {
            const value = result.value.scrollYProgress * 100;

            if (countdown) {
                onStarProgressUpdate(value);
            } else {
                onStarProgressUpdate(map(value, 0, LightStart, 0, 100));
                onLightProgressUpdate(map(value, LightStart, 100, 0, 100));
            }

            if (value < firstSectionStart) {
                section1Api.start({
                    opacity: 1,
                    y: 0,
                });
            } else {
                section1Api.start({
                    opacity: 0,
                    y: -10,
                });
            }

            if (value < secondSectionStart) {
                section2Api.start({
                    opacity: 0,
                    y: 10,
                });
            } else if (value > secondSectionStart && value < secondSectionEnd) {
                section2Api.start({
                    opacity: 1,
                    y: 0,
                });
            } else {
                section2Api.start({
                    opacity: 0,
                    y: -10,
                });
            }

            if (value < lastSectionStart) {
                section3Api.start({
                    opacity: 0,
                    y: 10,
                });
                setMainSectionActive(false);
            } else {
                section3Api.start({
                    opacity: 1,
                    y: 0,
                });
                setMainSectionActive(true);
            }
        },
        immediate: true,
        default: {
            immediate: true,
        },
    });

    const handleEnter = useCallback(() => {
        onEnter();
        section3Api.start({
            opacity: 0,
            y: 0,
        });
    }, [onEnter, section3Api]);

    return (
        <div className={classnames(styles.intro, className)}>
            {Array.from({ length: sectionNum }).map((_, index) => (
                <section key={index} className={styles.emptySection} />
            ))}

            <animated.div
                className={classnames(styles.content, styles.leadingText)}
                style={{
                    height: breakpoint === 'tablet' ? winHeight - 40 : winHeight,
                    opacity: section1Styles.opacity,
                    transform: section1Styles.y.to((value) => `translateY(${value}px)`),
                }}
            >
                <br />
                歡迎來到共聲島
                <br />
                穿越時空 追尋聲音的軌跡
            </animated.div>
            <animated.div
                className={classnames(styles.content, styles.leadingText)}
                style={{
                    height: breakpoint === 'tablet' ? winHeight - 40 : winHeight,
                    opacity: section2Styles.opacity,
                    transform: section2Styles.y.to((value) => `translateY(${value}px)`),
                }}
            >
                落山風與海浪的聲響
                <br />
                老街中的閒談和民謠
                <br />
                歷史的河流在此交匯
            </animated.div>
            <animated.div
                className={classnames(
                    styles.content,
                    countdown && styles.countdown,
                    !countdown && styles.enter,
                    styles.leadingText,
                )}
                style={{
                    ...(!staticMode && {
                        height: breakpoint === 'tablet' ? winHeight - 40 : winHeight,
                        paddingBottom: winWidth <= 800 ? (winHeight - 40) * 0.46 : winHeight * 0.46,
                    }),

                    ...(staticMode && {
                        paddingBottom: 0,
                    }),

                    opacity: section3Styles.opacity,
                    transform: section3Styles.y.to((value) => `translateY(${value}px)`),
                    pointerEvents: mainSectionActive ? 'initial' : 'none',
                }}
            >
                {countdown && <CountdownMainSection />}
                {!countdown && staticMode && <EnterStatic onEnter={handleEnter} />}
                {!countdown && !staticMode && <Enter onEnter={handleEnter} />}
            </animated.div>

            {/* --------------------------- */}
            {/* static land */}
            {/* {staticMode && (
                <div
                    className={styles.landWrapper}
                    style={{
                        width: winWidth,
                        height: winHeight,
                    }}
                >
                    <div className={classnames(styles.landSrc)} style={{}}>
                        <img src="/images/entire-island.png" alt="land" />
                    </div>
                </div>
            )} */}
        </div>
    );
};

export default Intro;
