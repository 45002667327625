import classnames from 'classnames';
import { useWindowSize } from '@/utils/hooks';

import Button from '@/components/atoms/Button';

import styles from './index.module.css';

export interface EnterStaticProp {
    className?: string;
    onEnter?: () => void;
}

const EnterStatic: React.FC<EnterStaticProp> = ({ className, onEnter = () => {} }) => {
    const { winWidth, winHeight } = useWindowSize();

    return (
        <div
            className={classnames(styles.enterStatic, className)}
            style={{
                width: winWidth,
                height: winHeight,
            }}
        >
            <img className={styles.title} src="/images/title.png" alt="title" />
            <div className={styles.subtitle}>耳朵裡的恆春半島</div>
            <Button className={styles.btn} onClick={onEnter} hoverType="shake">
                ENTER
            </Button>

            {/* --------------------------- */}
            {/* static land */}
            <div className={styles.landWrapper}>
                <div className={classnames(styles.landSrc)} style={{}}>
                    <img src="/images/entire-island.png" alt="land" />
                </div>
            </div>
        </div>
    );
};

export default EnterStatic;
