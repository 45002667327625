import classnames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { Element } from 'react-scroll';

import StaticIntro from '@/components/organisms/StaticIntro';
import StaticRouteIntro from '@/components/organisms/StaticRouteIntro';
import SectionHowTo from '@/components/organisms/SectionHowTo';
import SectionPurchase from '@/components/organisms/SectionPurchase';
import Faq from '@/components/organisms/SectionFaq';
import Contact from '@/components/organisms/SectionContact';
import StaticCredits from '@/components/organisms/StaticCredits';

import styles from './index.module.css';

export interface MainStaticProp {
    className?: string;
}

const MainStatic: React.FC<MainStaticProp> = ({ className }) => {
    const navigate = useNavigate();

    return (
        <div className={classnames(styles.mainStatic, className)}>
            <Element name="section-about">
                <StaticIntro />
            </Element>

            <Element name="section-route">
                <div className={styles.routeIntro}>
                    <StaticRouteIntro
                        onClickDetail={(route) => {
                            navigate(`/${route}`);
                        }}
                    />
                </div>
            </Element>

            <Element name="section-howto">
                <div className={styles.howto}>
                    <SectionHowTo />
                </div>
            </Element>

            <Element name="section-purchase">
                <div className={styles.purchase}>
                    <SectionPurchase onClickMore={() => navigate('/faq#purchase')} />
                </div>
            </Element>

            <Element name="section-faq">
                <div className={styles.faq}>
                    <Faq simple onClickMore={() => navigate('/faq')} />
                </div>
            </Element>

            <Element name="section-contact">
                <div className={styles.contact}>
                    <Contact />
                </div>
            </Element>

            <Element name="section-team">
                <div className={styles.credits}>
                    <StaticCredits />
                </div>
            </Element>
        </div>
    );
};

export default MainStatic;
