import classnames from 'classnames';
import { LINE_OFFICIAL_URL } from '@/utils/links';

import styles from './index.module.css';

export interface ShopFixedLinkProp {
    className?: string;
    active?: boolean;
}

const ShopFixedLink: React.FC<ShopFixedLinkProp> = ({ className, active }) => (
    <a
        className={classnames(styles.shopFixedLink, active && styles.active, className)}
        href={LINE_OFFICIAL_URL}
        target="_blank"
        rel="noopener noreferrer"
    >
        <div className={styles.land}>
            <img src="/images/rocket.png" alt="land-single" />
        </div>
        <div className={styles.label}>登陸共聲島</div>
    </a>
);

export default ShopFixedLink;
