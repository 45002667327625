import classnames from 'classnames';

import PageLayout from '@/components/organisms/PageLayout';
import Title from '@/components/atoms/Title';
import Button from '@/components/atoms/Button';

import styles from './index.module.css';
import { SHOP_URL } from '@/utils/links';

export interface LayoutAboutLoveProp {
    className?: string;
    active?: boolean;
}

const LayoutAboutLove: React.FC<LayoutAboutLoveProp> = ({ className, active }) => (
    <PageLayout active={active}>
        <div className={classnames(styles.layoutAboutLove, className)}>
            <Title>戀の時光町</Title>

            <section className={styles.intro}>
                「戀の時光町」是由無數愛情故事組成的歷史街區，參加此體驗的旅人可跟隨導覽員的步伐、打開穿越時空的入口。導覽員一邊解説、一邊引導旅人靠近埋藏歲月秘密的建築，聆聽五則關於「愛」的故事。
                <br />
                「戀の時光町」體驗全程有五個重要的地點，透過耳機能聽見五位時空背景各異的女性，在歷史長河的流動中，因著不同的緣份由來到共聲島。他們娓娓道來那段甜蜜或心碎的往事，並留下了未竟的心願，唯有透過手機互動系統幫她們實現。
            </section>
            <div className={styles.itemWrapper}>
                <div className={styles.item}>
                    <div className={styles.name}>體驗時間</div>
                    <div className={styles.value}>約 90 分鐘</div>
                </div>
                <div className={styles.item}>
                    <div className={styles.name}>集合地點</div>
                    <div className={styles.value}>
                        <span>海角七號阿嘉的家</span>
                        <br />
                        <span style={{ marginLeft: -8 }}>（恆春鎮光明路90號）</span>
                    </div>
                </div>
                <div className={styles.item}>
                    <div className={styles.name}>名額</div>
                    <div className={styles.value}>10 人成團，上限 15 人</div>
                </div>
            </div>

            <a className={styles.btn} href={SHOP_URL} target="_blank" rel="noopener noreferrer">
                <Button>解鎖路線</Button>
            </a>

            {/* <div className={styles.bottom}>
                <img src="/images/aboutlove.png" alt="aboutlove" />
            </div> */}
        </div>
    </PageLayout>
);

export default LayoutAboutLove;
