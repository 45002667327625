import classnames from 'classnames';
import { useEffect, useState, useMemo, useRef } from 'react';
import { Element, scroller } from 'react-scroll';

import Title from '@/components/atoms/Title';
import DrawerItem from '@/components/molecules/DrawerItem';

import { faqList, FAQRawItem } from './data';

import styles from './index.module.css';

export interface SectionFaqProp {
    className?: string;
    simple?: boolean;
    filter?: FAQRawItem['type'];
    onFilterChange?: (filter: FAQRawItem['type']) => void;
    onClickMore?: () => void;
}

const filters: { key: FAQRawItem['type']; label: string }[] = [
    { key: 'about', label: '關於共聲島' },
    { key: 'howTo', label: '體驗資訊' },
    { key: 'purchase', label: '購票相關' },
    { key: 'device', label: '設備問題' },
];

const Faq: React.FC<SectionFaqProp> = ({
    className,
    simple = true,
    filter = 'about',
    onFilterChange = () => {},
    onClickMore = () => {},
}) => {
    const dataList = useMemo(() => {
        return faqList.filter(({ type }) => {
            if (simple) {
                return type === 'about';
            }
            return type === filter;
        });
    }, [simple, filter]);

    const [activeItemId, setActiveItemId] = useState(simple ? 1 : -1);

    const activeItemIdRef = useRef(activeItemId);
    useEffect(() => {
        activeItemIdRef.current = activeItemId;
    }, [activeItemId]);

    // useEffect(() => {
    //     if (!dataList.find((data) => data.id === activeItemIdRef.current)) {
    //         setActiveItemId(dataList[0].id);
    //     }
    // }, [dataList]);

    return (
        <div className={classnames(styles.faq, className)}>
            <Title className={classnames(styles.title, !simple && styles.withFilter)}>FAQ</Title>

            {!simple && (
                <div className={styles.filterGroup}>
                    {filters.map((f) => (
                        <div
                            key={f.key}
                            className={classnames(styles.filter, f.key === filter && styles.active)}
                            onClick={() => {
                                onFilterChange(f.key);
                            }}
                        >
                            {f.label}
                        </div>
                    ))}
                </div>
            )}

            <div className={styles.listWrapper}>
                {dataList.map(({ id, type, question, answer }) => (
                    <div key={id} className={styles.itemWrapper}>
                        <Element name={String(id)}>
                            <DrawerItem
                                open={activeItemId === id}
                                title={`${id}. ${question}`}
                                content={answer}
                                onToggle={(opened) => {
                                    setActiveItemId(opened ? id : -1);
                                }}
                                onClickItem={(clickedId) => {
                                    // onFilterChange(type);
                                    scroller.scrollTo(String(clickedId), {
                                        smooth: true,
                                        containerId: 'page',
                                        offset: -150,
                                    });
                                    setActiveItemId(clickedId);
                                }}
                            />
                        </Element>
                    </div>
                ))}
            </div>

            {simple && (
                <div className={styles.more} onClick={onClickMore}>
                    查看更多
                </div>
            )}
        </div>
    );
};

export default Faq;
