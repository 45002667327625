import classnames from 'classnames';

import styles from './index.module.css';

export interface NoiseProp {
    className?: string;
    noiseSize?: number;
}

const Noise: React.FC<NoiseProp> = ({ className, noiseSize = 3 }) => {
    return (
        <div className={classnames(styles.noise, className)}>
            <div
                className={styles.scaleWrapper}
                style={{
                    transform: `scale(${noiseSize + 1})`,
                }}
            >
                <div
                    className={classnames(styles.noiseSrc, className)}
                    style={{
                        background: `url(/images/noise.png) lightgray 0% 0% / 100px 100px repeat`,
                    }}
                />
            </div>
        </div>
    );
};

export default Noise;
