import classnames from 'classnames';
import { useWindowSize } from '@/utils/hooks';
import { animated, useSpring, config } from '@react-spring/web';

import pages, { PageType } from '@/utils/pages';

import styles from './index.module.css';

export interface MobileMenuProp {
    className?: string;
    active?: boolean;
    activePage?: PageType;
    onClickPage?: (page: PageType) => void;
    onClose?: () => void;
}

const MobileMenu: React.FC<MobileMenuProp> = ({
    className,
    active,
    activePage,
    onClickPage = () => {},
    onClose = () => {},
}) => {
    const { winHeight } = useWindowSize();

    const { top } = useSpring({
        top: active ? 0 : 110,
        config: config.slow,
    });

    return (
        <animated.div
            className={classnames(styles.mobileMenu, className)}
            style={{ top: top.to((value) => `-${value}vh`) }}
        >
            <nav
                className={styles.container}
                style={{
                    height: winHeight,
                }}
            >
                {pages.map(({ key, label }) => (
                    <div
                        key={key}
                        className={classnames(styles.navItem, key === activePage && styles.active)}
                        onClick={() => {
                            onClickPage(key);
                            onClose();
                        }}
                    >
                        {label}
                    </div>
                ))}
            </nav>
        </animated.div>
    );
};

export default MobileMenu;
