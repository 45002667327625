import classnames from 'classnames';
import { useEffect, useMemo, useState, useRef } from 'react';
import { animated, useSpring, config } from '@react-spring/web';
import { createBreakpoint } from 'react-use';

import Noise from '@/components/molecules/Noise';
import StarLayer from '@/components/molecules/StarLayer';

import { map, interpolateColor } from '@/utils/math';

import styles from './index.module.css';
import { useWindowSize } from '@/utils/hooks';

export interface SkyBackgroundProp {
    className?: string;

    starProgress: number;
    lightProgress: number;
    mainProgress: number;
    groundProgress: number;
    underneathProgress: number;

    staticMode?: boolean;

    underneathTransiing?: boolean;
}

type Theme = 'dark' | 'light' | 'main' | 'ground' | 'underneath';
const useBreakpoint = createBreakpoint({ laptopL: 1440, laptop: 1024, tablet: 600, phone: 500 });

const bgSrcWidth = 1440;
const bgSrcHeight = 1742;

const groundSrcWidth = 2000;
const groundSrcHeight = 711;

const SkyBackground: React.FC<SkyBackgroundProp> = ({
    className,
    starProgress,
    lightProgress,
    mainProgress,
    groundProgress,
    underneathProgress,

    staticMode,

    underneathTransiing = false,
}) => {
    const breakpoint = useBreakpoint();
    const { winWidth, winHeight } = useWindowSize();

    const theme = useMemo<Theme>(() => {
        if (underneathProgress > 0) {
            return 'underneath';
        }

        if (groundProgress > 0) {
            return 'ground';
        }

        if (mainProgress > 0) {
            return 'main';
        }

        if (lightProgress > 0) {
            return 'light';
        }

        return 'dark';
    }, [lightProgress, mainProgress, groundProgress, underneathProgress]);

    const backgroundColorTarget = useMemo(() => {
        if (theme === 'dark') {
            return '#0A0B16';
        }

        if (theme === 'light') {
            return interpolateColor('#0A0B16', '#5A6AED', lightProgress / 100);
        }

        // main
        return '#5A6AED';
    }, [theme, lightProgress]);

    const noiseLayer1OpacityTarget = useMemo(() => {
        if (theme === 'dark') {
            return 1;
        }

        if (theme === 'light') {
            return map(lightProgress, 0, 100, 1, 0);
        }

        return 0;
    }, [theme, lightProgress]);

    const noiseLayer2OpacityTarget = useMemo(() => {
        if (theme === 'dark') {
            return 0.25;
        }

        if (theme === 'light') {
            return map(lightProgress, 0, 100, 0, 0.25);
        }

        return 0.25;
    }, [theme, lightProgress]);

    const starLayer1YTarget = useMemo(() => {
        if (theme === 'dark') {
            return map(starProgress, 0, 100, 0, -20);
        }

        return map(lightProgress, 0, 100, -20, -40);
    }, [theme, starProgress, lightProgress]);

    const starLayer2YTarget = useMemo(() => {
        if (theme === 'dark') {
            return map(starProgress, 0, 100, 0, -30);
        }

        return map(lightProgress, 0, 100, -30, -70);
    }, [theme, starProgress, lightProgress]);

    const cloudLayer1YTarget = useMemo(() => {
        if (theme === 'main') {
            return 0;
        }

        if (theme === 'light') {
            return map(lightProgress, 0, 100, 100, 0);
        }

        if (theme === 'dark') {
            return 100;
        }

        return 0;
    }, [theme, lightProgress]);

    const cloudLayerY2Target = useMemo(() => {
        if (theme === 'main') {
            return 49;
        }

        if (theme === 'light') {
            return map(lightProgress, 0, 100, 200, 49);
        }

        if (theme === 'dark') {
            return 200;
        }

        return 49;
    }, [theme, lightProgress]);

    const gradientHeightTarget = useMemo(() => {
        if (theme === 'underneath') {
            return 150;
        }

        if (theme === 'ground') {
            return map(groundProgress, 0, 100, 100, 150);
        }

        if (theme === 'main') {
            return map(mainProgress, 0, 100, 45, 150);
        }

        if (theme === 'light') {
            return map(lightProgress, 0, 100, 0, 45);
        }

        return 0;
    }, [theme, lightProgress, mainProgress, groundProgress]);

    const gradientYTarget = useMemo(() => {
        if (theme === 'underneath') {
            return map(underneathProgress, 0, 100, -20, 0);
        }

        return -20;
    }, [theme, underneathProgress]);

    const groundYTarget = useMemo(() => {
        const groundHeight =
            (((breakpoint === 'phone' ? 1.5 : 1) * winWidth) / groundSrcWidth) * groundSrcHeight;

        const groundStageDisplayMax = winHeight * 0.4;
        const groundStageDisplayHeight =
            groundStageDisplayMax < groundHeight ? groundHeight - groundStageDisplayMax : 0;

        if (theme === 'underneath') {
            return map(underneathProgress, 0, 100, groundStageDisplayHeight * -1, winHeight - 15);
        }

        if (theme === 'ground') {
            return map(groundProgress, 0, 100, groundHeight * -1, groundStageDisplayHeight * -1);
        }

        return groundHeight * -1;
    }, [theme, winWidth, winHeight, breakpoint, groundProgress, underneathProgress]);

    const {
        backgroundColor,
        noiseLayer1Opacity,
        noiseLayer2Opacity,
        starLayer1Y,
        starLayer2Y,
        cloudLayer1Y,
        cloudLayer2Y,
        gradientHeight,
        gradientY,
        groundY,
    } = useSpring({
        backgroundColor: backgroundColorTarget,
        noiseLayer1Opacity: noiseLayer1OpacityTarget,
        noiseLayer2Opacity: noiseLayer2OpacityTarget,
        starLayer1Y: starLayer1YTarget,
        starLayer2Y: starLayer2YTarget,
        cloudLayer1Y: cloudLayer1YTarget,
        cloudLayer2Y: cloudLayerY2Target,
        gradientHeight: gradientHeightTarget,
        gradientY: gradientYTarget,
        groundY: groundYTarget + 60,

        config: config.slow,

        immediate: groundProgress > 0 || underneathProgress > 0,
    });

    const starNum = useMemo(() => {
        if (breakpoint === 'laptopL') {
            return 20;
        }

        if (breakpoint === 'laptop') {
            return 20;
        }

        if (breakpoint === 'tablet') {
            return 13;
        }

        return 13;
    }, [breakpoint]);

    const cloudScale = useMemo(() => {
        if (breakpoint === 'tablet') {
            return winWidth * 0.0008;
        }

        if (breakpoint === 'phone') {
            return winWidth * 0.001;
        }

        return winWidth * 0.0005;
    }, [breakpoint, winWidth]);

    return (
        <animated.div
            className={classnames(styles.skyBackground, className)}
            style={{
                backgroundColor,
            }}
        >
            <animated.div
                className={styles.gradient}
                style={{
                    width: '100vw',
                    height: `calc(${(100 / bgSrcWidth) * bgSrcHeight}vw)`,
                    transform: gradientHeight.to(
                        (value) =>
                            `scaleY(${(winHeight / ((100 / bgSrcWidth) * bgSrcHeight * winWidth)) * 100 * (value / 100)})`,
                    ),
                    bottom: gradientY.to((value) => `${value}%`),
                }}
            >
                <img
                    src="/images/bg-gradient.svg"
                    style={{
                        width: '100vw',
                        height: `calc(${(100 / bgSrcWidth) * bgSrcHeight}vw)`,
                    }}
                    alt="gradient"
                />
            </animated.div>
            <animated.div
                className={styles.gradient}
                style={{
                    width: '100vw',
                    height: `calc(${(100 / bgSrcWidth) * bgSrcHeight}vw)`,
                    transform: gradientHeight.to(
                        (value) =>
                            `scaleY(${(winHeight / ((100 / bgSrcWidth) * bgSrcHeight * winWidth)) * 100 * (value / 100)})`,
                    ),
                    bottom: gradientY.to((value) => `${value}%`),
                    opacity: 0.3,
                }}
            >
                <img
                    src="/images/bg-gradient.svg"
                    style={{
                        width: '100vw',
                        height: `calc(${(100 / bgSrcWidth) * bgSrcHeight}vw)`,
                    }}
                    alt="gradient"
                />
            </animated.div>
            {/* --------------------------- */}
            {/* star layer */}
            <animated.div
                className={styles.starLayer}
                style={{
                    transform: starLayer1Y.to((value) => `translateY(${value}%)`),
                }}
            >
                <StarLayer num={starNum} />
            </animated.div>
            <animated.div
                className={styles.starLayer}
                style={{
                    transform: starLayer2Y.to((value) => `translateY(${value}%)`),
                }}
            >
                <StarLayer num={starNum} />
            </animated.div>
            {/* --------------------------- */}
            {/* noise */}
            <animated.div
                className={styles.noiseWrapper}
                style={{
                    opacity: noiseLayer1Opacity,
                }}
            >
                <Noise noiseSize={2} />
            </animated.div>
            <animated.div
                className={styles.noiseWrapper}
                style={{
                    opacity: noiseLayer2Opacity,
                }}
            >
                <Noise noiseSize={1} />
            </animated.div>
            {/* --------------------------- */}
            {/* ground */}
            <animated.div
                className={classnames(styles.ground, breakpoint === 'phone' && styles.mobile)}
                style={{
                    bottom: groundY,
                    opacity: theme === 'ground' || theme === 'underneath' ? 1 : 0,
                }}
            >
                <img src="/images/ground.png" alt="ground" />
            </animated.div>
            <animated.div
                className={styles.groundMask}
                style={{
                    bottom: groundY.to((value) => value - winHeight),
                }}
            />
            <animated.div
                className={styles.groundShadow}
                style={{
                    bottom: groundY.to((value) => value - 49),
                }}
            />

            {/* --------------------------- */}
            {/* clouds */}
            <animated.div
                className={classnames(styles.cloudEnterLayer, styles.cloudEnterLayer1)}
                style={{
                    transform: cloudLayer1Y.to((value) => `translateY(${value}%)`),
                }}
            >
                <div
                    className={classnames(styles.cloud)}
                    style={{
                        right: breakpoint === 'phone' || breakpoint === 'tablet' ? '-17%' : '-5%',
                        bottom: '30%',
                    }}
                >
                    <img
                        src="/images/cloud_1.png"
                        width={364 * cloudScale}
                        height={45 * cloudScale}
                        alt="cloud"
                    />
                </div>
                <div
                    className={classnames(styles.cloud)}
                    style={{
                        left: '-8%',
                        bottom: '10%',
                    }}
                >
                    <img
                        src="/images/cloud_3.png"
                        width={429 * cloudScale}
                        height={53 * cloudScale}
                        alt="cloud"
                    />
                </div>
            </animated.div>
            <animated.div
                className={classnames(styles.cloudEnterLayer, styles.cloudEnterLayer2)}
                style={{
                    transform: cloudLayer2Y.to((value) => `translateY(${value}%)`),
                }}
            >
                <div
                    className={classnames(styles.cloud)}
                    style={{
                        right: '15%',
                        bottom: '70%',
                    }}
                >
                    <img
                        src="/images/cloud_4.png"
                        width={108 * cloudScale}
                        height={13 * cloudScale}
                        alt="cloud"
                    />
                </div>
                <div
                    className={classnames(styles.cloud)}
                    style={{
                        left: '20%',
                        bottom: '50%',
                    }}
                >
                    <img
                        src="/images/cloud_2.png"
                        width={107 * 1.5 * cloudScale}
                        height={13 * 1.5 * cloudScale}
                        alt="cloud"
                    />
                </div>
            </animated.div>
        </animated.div>
    );
};

export default SkyBackground;
