import { useMemo, useRef } from 'react';
import classnames from 'classnames';
import { gsap } from 'gsap';
import { useGSAP } from '@gsap/react';

import styles from './index.module.css';
import { random } from '@/utils/math';

export interface StarProp {
    className?: string;
    twinkling?: boolean;
    scale?: number;
    x?: number;
    y?: number;
    angle?: number;
}

const srcWidth = 190;
const srcHeight = 174;

const baseWidth = 20;
const baseHeight = 20;

gsap.registerPlugin(useGSAP);

const Star: React.FC<StarProp> = ({ className, twinkling = false, scale = 1, x, y, angle = 0 }) => {
    const adjustedSrcWidth = useMemo(() => baseWidth * scale, [scale]);
    const adjustedSrcHeight = useMemo(() => (baseWidth / srcWidth) * srcHeight * scale, [scale]);

    const starWrapper = useRef<HTMLDivElement | null>(null);

    useGSAP(
        () => {
            const repeatDelay = random(0.5, 5);

            const timeline = gsap.timeline({
                paused: true,
                repeat: -1,
                repeatDelay,
            });
            timeline
                .fromTo(starWrapper.current, { opacity: 1, scale: 1 }, { opacity: 0.1, scale: 0.8 })
                .to(starWrapper.current, { opacity: 1, scale: 1 });

            if (twinkling) {
                timeline.play();
            }
        },
        {
            dependencies: [twinkling],
            revertOnUpdate: true,
        },
    );

    return (
        <div
            className={classnames(
                styles.star,
                (typeof x === 'number' || typeof y === 'number') && styles.absolute,
                className,
            )}
            style={{
                ...(x && {
                    left: `${x}%`,
                }),
                ...(y && {
                    top: `${y}%`,
                }),
                ...(angle && {
                    transform: `rotate(${angle}deg)`,
                }),
            }}
        >
            <div
                ref={starWrapper}
                style={{
                    width: baseWidth,
                    height: baseHeight,
                }}
            >
                <img
                    src="/images/star.png"
                    width={adjustedSrcWidth}
                    height={adjustedSrcHeight}
                    alt="star"
                />
            </div>
        </div>
    );
};

export default Star;
