interface RouteData {
    key: string;
    title: string;
    content: string;
    locked?: boolean;
    infos: { label: string; value: string }[];
}

export const route1: RouteData = {
    key: 'aboutlove',
    title: '戀の時光町',
    locked: false,
    content: `有聲音從戀の時光町傳出來，
    你有聽見嗎？

    女子們訴說著
    關於古城、歷史、愛情的往事
    是日常景色，還是難以復返的情懷？`,
    infos: [
        {
            label: '開放時間',
            value: '2024.06.13 -',
        },
        {
            label: '體驗時長',
            value: '90 分鐘',
        },
        {
            label: '地點',
            value: '恆春鎮',
        },
    ],
};

export const route2: RouteData = {
    key: 'aboutdestiny',
    title: '限時轉運站',
    locked: true,
    content: `車水馬龍的老街區
    你將得到一個神奇的虛擬聊天室

    透過與店家互動收集「碎片」
    揭開聊天室背後的神秘面紗
    來趟最具挑戰性的「轉運」之旅吧！`,
    infos: [
        {
            label: '完工時間',
            value: '2024.10',
        },
        {
            label: '體驗時長',
            value: '未知',
        },
        {
            label: '地點',
            value: '恆春鎮',
        },
    ],
};

export const route3: RouteData = {
    key: 'aboutresonance',
    title: '共聲電台',
    locked: true,
    content: `共聲電台佇立在島上一隅
    不畏風雨
    默默記錄下從古至今珍貴的聲音
    頻頻發出電波
    路過的你，打開手機尋找吧！`,
    infos: [
        {
            label: '完工時間',
            value: '2024.10',
        },
        {
            label: '體驗時長',
            value: '未知',
        },
        {
            label: '地點',
            value: '恆春鎮',
        },
    ],
};

export const routeList = [route1, route2, route3];
