import classnames from 'classnames';
import { useRef } from 'react';
import { gsap } from 'gsap';
import { useGSAP } from '@gsap/react';

import styles from './index.module.css';

export interface LockProp {
    className?: string;
    active?: boolean;
    twinkling?: boolean;
}

const Lock: React.FC<LockProp> = ({ className, active, twinkling }) => {
    const ref = useRef<HTMLDivElement | null>(null);

    useGSAP(
        () => {
            const repeatDelay = 0;

            if (active) {
                gsap.fromTo(
                    ref.current,
                    {
                        opacity: 0,
                    },
                    {
                        opacity: 1,
                        onComplete: () => {
                            if (twinkling) {
                                const timeline = gsap.timeline({
                                    paused: true,
                                    delay: 0.6,
                                    repeat: -1,
                                    repeatDelay,
                                });
                                timeline
                                    .fromTo(
                                        ref.current,
                                        { opacity: 1 },
                                        { opacity: 0.5, duration: 0.5 },
                                    )
                                    .to(ref.current, { opacity: 1, duration: 0.5 });

                                timeline.play();
                            }
                        },
                    },
                );
            } else {
                gsap.to(ref.current, { opacity: 0 });
            }
        },
        {
            dependencies: [active, twinkling],
            revertOnUpdate: true,
        },
    );

    return (
        <div ref={ref} className={classnames(styles.lock, className)}>
            <img src="/images/locked.png" alt="lock" />
        </div>
    );
};

export default Lock;
