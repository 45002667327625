import classnames from 'classnames';
import { animated, useSpring } from '@react-spring/web';
import { useWindowSize } from '@/utils/hooks';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faClose } from '@fortawesome/free-solid-svg-icons';

import MobileMenu from '@/components/molecules/MobileMenu';

import pages, { PageType } from '@/utils/pages';

import styles from './index.module.css';

export interface HeaderProp {
    className?: string;
    active?: boolean;
    stage?: 'title' | 'main' | 'none';
    mobileMenuOpen?: boolean;
    activePage?: PageType;
    onClickTitle?: () => void;
    onClickPage?: (page: PageType) => void;
    onToggleMobileMenu?: (nextState: boolean) => void;
}

const Header: React.FC<HeaderProp> = ({
    className,
    active,
    stage = 'title',
    mobileMenuOpen = false,
    activePage,
    onClickTitle = () => {},
    onClickPage = () => {},
    onToggleMobileMenu = () => {},
}) => {
    const { winWidth } = useWindowSize();

    const titleStyles = useSpring({
        opacity: active && stage === 'title' ? 1 : 0,
    });

    const headerStyles = useSpring({
        opacity: active && stage === 'main' ? 1 : 0,
        top: active && stage === 'main' ? 0 : -100,
    });

    return (
        <>
            <animated.div
                className={styles.titleAlone}
                style={{
                    ...titleStyles,
                }}
                onClick={onClickTitle}
            >
                <img src="/images/title.png" alt="title" />
            </animated.div>
            <animated.div
                className={classnames(styles.header, className)}
                style={{
                    ...headerStyles,
                }}
            >
                <div className={styles.title} onClick={onClickTitle}>
                    <img src="/images/title.png" alt="title" />
                </div>
                {winWidth > 1201 && (
                    <nav className={classnames(styles.nav)}>
                        {pages.map(({ key, label }) => (
                            <div
                                key={key}
                                className={classnames(
                                    styles.navItem,
                                    key === activePage && styles.active,
                                )}
                                onClick={() => {
                                    onClickPage(key);
                                }}
                            >
                                {label}
                            </div>
                        ))}
                    </nav>
                )}
                {winWidth <= 1200 && (
                    <nav className={classnames(styles.nav)}>
                        <div
                            className={styles.menuIcon}
                            onClick={() => onToggleMobileMenu(!mobileMenuOpen)}
                        >
                            <FontAwesomeIcon icon={mobileMenuOpen ? faClose : faBars} />
                        </div>
                    </nav>
                )}
                <MobileMenu
                    active={mobileMenuOpen}
                    activePage={activePage}
                    onClickPage={onClickPage}
                    onClose={() => onToggleMobileMenu(false)}
                />
            </animated.div>
        </>
    );
};

export default Header;
