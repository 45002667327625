import classnames from 'classnames';

import styles from './index.module.css';

export interface TitleProp {
    className?: string;
    children: React.ReactNode;
}

const Title: React.FC<TitleProp> = ({ className, children }) => (
    <div className={classnames(styles.title, className)}>{children}</div>
);

export default Title;
