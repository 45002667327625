import classnames from 'classnames';
import { useState } from 'react';
import { animated, useSpring, config } from '@react-spring/web';
import { useWindowSize } from '@/utils/hooks';

import Rocket from '@/components/atoms/Rocket';

import styles from './index.module.css';

export interface RocketGroupProp {
    className?: string;
    stage: 'loading' | 'scrolling' | 'none';
    small?: boolean;
}

const posMap: Record<RocketGroupProp['stage'], number | 'center'> = {
    loading: 'center',
    scrolling: 24,
    none: -130,
};

const hintMap: Record<RocketGroupProp['stage'], string> = {
    loading: 'LOADING',
    scrolling: 'SCROLL',
    none: '',
};

const RocketGroup: React.FC<RocketGroupProp> = ({ className, stage, small = false }) => {
    const { winHeight } = useWindowSize();

    const [stageChanging, setStageChanging] = useState(false);

    const [nextStage, setNextStage] = useState(stage);

    const { textHeight, textOpacity } = useSpring({
        textHeight: small ? 0 : 30,
        textOpacity: stageChanging ? 0 : 1,
        immediate: stageChanging,
    });

    const { bottom, scale } = useSpring({
        to: {
            bottom: posMap[stage] === 'center' ? winHeight * 0.5 - 80 : posMap[stage],
            scale: small ? 0.8 : 1,
        },
        config: config.slow,
        delay: 1,
        onStart: () => {
            setStageChanging(true);
        },
        onRest: () => {
            setStageChanging(false);
            setNextStage(stage);
        },
    });

    return (
        <animated.div
            className={classnames(styles.rocketGroup, className)}
            style={{
                bottom,
                transform: scale.to((value) => `scale(${value})`),
            }}
        >
            <Rocket moving={stage === 'loading'} />
            <animated.div
                className={styles.hint}
                style={{
                    height: textHeight,
                    opacity: textOpacity,
                }}
            >
                {hintMap[nextStage]}
            </animated.div>
        </animated.div>
    );
};

export default RocketGroup;
