import classnames from 'classnames';
import { useState } from 'react';
import { animated, useSpring, config } from '@react-spring/web';
import { useWindowSize } from '@/utils/hooks';
import { useNavigate } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';

import styles from './index.module.css';

export interface PageLayoutProp {
    className?: string;
    active?: boolean;
    children: React.ReactNode;
    onClose?: () => void;
}

const PageLayout: React.FC<PageLayoutProp> = ({
    className,
    active,
    children,
    onClose = () => {},
}) => {
    const navigate = useNavigate();
    const { winWidth, winHeight } = useWindowSize();

    const [render, setRender] = useState(active);

    const { top } = useSpring({
        top: active ? 0 : winHeight * 1.1,
        config: config.slow,
        onStart: () => {
            if (active) {
                setRender(true);
            }
        },
        onRest: () => {
            if (!active) {
                setRender(false);
            }
        },
    });

    if (!render) {
        return <div />;
    }

    return (
        <animated.div
            id="page"
            className={classnames(styles.pageLayout, className)}
            style={{ width: winWidth, height: winHeight, top }}
        >
            <animated.div
                className={styles.close}
                style={{
                    top: top.to((value) => value + 20),
                }}
            >
                <FontAwesomeIcon
                    icon={faClose}
                    size="xl"
                    onClick={() => {
                        navigate('/');
                    }}
                />
            </animated.div>
            {render && children}
        </animated.div>
    );
};

export default PageLayout;
