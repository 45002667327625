import classnames from 'classnames';

import PageLayout from '@/components/organisms/PageLayout';
import Faq, { SectionFaqProp } from '@/components/organisms/SectionFaq';

import styles from './index.module.css';

export interface LayoutFaqProp {
    className?: string;
    active?: boolean;
    filter?: SectionFaqProp['filter'];
    onClickFilter?: (filter: SectionFaqProp['filter']) => void;
}

const LayoutFaq: React.FC<LayoutFaqProp> = ({
    className,
    active,
    filter = 'about',
    onClickFilter = () => {},
}) => {
    return (
        <PageLayout active={active} className={classnames(styles.layoutFaq, className)}>
            <Faq simple={false} filter={filter} onFilterChange={onClickFilter} />
        </PageLayout>
    );
};

export default LayoutFaq;
