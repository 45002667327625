import classnames from 'classnames';
import { useCallback, useState } from 'react';
import { useInterval } from 'react-use';

import styles from './index.module.css';

export interface CountdownProp {
    className?: string;
    year: number;
    month: number;
    day: number;
    hour: number;
    minute: number;
}

const Countdown: React.FC<CountdownProp> = ({ className, year, month, day, hour, minute }) => {
    const [leftDays, setLeftDays] = useState(0);
    const [leftHours, setLeftHours] = useState(0);
    const [leftMinutes, setLeftMinutes] = useState(0);
    const [leftSeconds, setLeftSeconds] = useState(0);

    const update = useCallback(() => {
        const yearStr = `${year}`;
        const monthStr = month > 10 ? `${month}` : `0${month}`;
        const dayStr = day > 10 ? `${day}` : `0${day}`;
        const hourStr = hour > 10 ? `${hour}` : `0${hour}`;
        const minuteStr = minute > 10 ? `${minute}` : `0${minute}`;

        const now = new Date().getTime();
        const targetTime = new Date(
            `${yearStr}-${monthStr}-${dayStr}T${hourStr}:${minuteStr}:00`,
        ).getTime();

        const distance = targetTime - now;

        const nextLeftSeconds = Math.floor((distance % (1000 * 60)) / 1000);
        const nextLeftMinutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const nextLeftHours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const nextLeftDays = Math.floor(distance / (1000 * 60 * 60 * 24));

        setLeftSeconds(nextLeftSeconds);
        setLeftMinutes(nextLeftMinutes);
        setLeftHours(nextLeftHours);
        setLeftDays(nextLeftDays);
    }, [year, month, day, hour, minute]);

    useInterval(() => {
        update();
    }, 1000);

    return (
        <div className={classnames(styles.countdown, className)}>
            {leftDays} 天 {leftHours} 小時 {leftMinutes} 分 {leftSeconds} 秒
        </div>
    );
};

export default Countdown;
