import classnames from 'classnames';
import { useCallback, useRef, useState, useEffect } from 'react';
import { useMeasure } from 'react-use';
import { animated, useSpring, useScroll } from '@react-spring/web';
import { useWindowSize } from '@/utils/hooks';
import { useNavigate } from 'react-router-dom';
import { Element } from 'react-scroll';

import RouteIntro from '@/components/molecules/RouteIntro';
import SectionHowTo from '@/components/organisms/SectionHowTo';
import SectionPurchase from '@/components/organisms/SectionPurchase';
import SectionFaq from '@/components/organisms/SectionFaq';
import SectionContact from '@/components/organisms/SectionContact';
import SectionCredits from '../SectionCredits';
import StaticCredits from '@/components/organisms/StaticCredits';

import creditsData from '@/utils/credits';

import styles from './index.module.css';
import { map } from '@/utils/math';

export interface MainProp {
    className?: string;
    activeRoute: 1 | 2 | 3;

    onProgressUpdate?: (value: number) => void;
    onMainIntroProgressUpdate?: (value: number) => void;
    onEnterRouteProgressUpdate?: (value: number) => void;
    onRouteProgressUpdate?: (value: number) => void;
    onLeavingProgressUpdate?: (value: number) => void;
    onStaticContentProgress?: (value: number) => void;
    onGroundProgress?: (value: number) => void;
    onUndergroundEnteringProgress?: (value: number) => void;
    onUndergroundProgress?: (value: number) => void;
}

type SectionType =
    | 'mainIntro'
    | 'enterRoute'
    | 'route'
    | 'landLeaving'
    | 'staticContent'
    | 'ground'
    | 'undergroundEntering'
    | 'underground';

const groundOffsetBefore = -300;
const groundOffsetAfter = -300;

const Main: React.FC<MainProp> = ({
    className,
    activeRoute,
    onProgressUpdate = () => {},
    onMainIntroProgressUpdate = () => {},
    onEnterRouteProgressUpdate = () => {},
    onRouteProgressUpdate = () => {},
    onLeavingProgressUpdate = () => {},
    onStaticContentProgress = () => {},
    onGroundProgress = () => {},
    onUndergroundEnteringProgress = () => {},
    onUndergroundProgress = () => {},
}) => {
    const { winHeight } = useWindowSize();
    const navigate = useNavigate();

    const [displayScroll, setDisplayScroll] = useState(false);

    const winHeightRef = useRef(winHeight);
    useEffect(() => {
        winHeightRef.current = winHeight;
    }, [winHeight]);

    const [rootRef, { height: rootHeight }] = useMeasure<HTMLDivElement>();
    const rootHeightRef = useRef(rootHeight);
    useEffect(() => {
        rootHeightRef.current = rootHeight;
    }, [rootHeight]);

    const [sMainIntroRef, { height: sMainIntroHeight }] = useMeasure<HTMLDivElement>();
    const sMainIntroHeightRef = useRef(sMainIntroHeight);
    useEffect(() => {
        sMainIntroHeightRef.current = sMainIntroHeight;
    }, [sMainIntroHeight]);

    const [sEnterRouteRef, { height: sEnterRouteHeight }] = useMeasure<HTMLDivElement>();
    const sEnterRouteHeightRef = useRef(sEnterRouteHeight);
    useEffect(() => {
        sEnterRouteHeightRef.current = sEnterRouteHeight;
    }, [sEnterRouteHeight]);

    const [sRouteRef, { height: sRouteHeight }] = useMeasure<HTMLDivElement>();
    const sRouteHeightRef = useRef(sRouteHeight);
    useEffect(() => {
        sRouteHeightRef.current = sRouteHeight;
    }, [sRouteHeight]);

    const [sLeavingRef, { height: sLeavingHeight }] = useMeasure<HTMLDivElement>();
    const sLeavingHeightRef = useRef(sLeavingHeight);
    useEffect(() => {
        sLeavingHeightRef.current = sLeavingHeight;
    }, [sLeavingHeight]);

    const [sStaticRef, { height: sStaticHeight }] = useMeasure<HTMLDivElement>();
    const sStaticHeightRef = useRef(sStaticHeight);
    useEffect(() => {
        sStaticHeightRef.current = sStaticHeight;
    }, [sStaticHeight]);

    const [sGroundRef, { height: sGroundHeight }] = useMeasure<HTMLDivElement>();
    const sGroundHeightRef = useRef(sGroundHeight);
    useEffect(() => {
        sGroundHeightRef.current = sGroundHeight;
    }, [sGroundHeight]);

    const [sUndergroundEnteringRef, { height: sUndergroundEnteringHeight }] =
        useMeasure<HTMLDivElement>();
    const sUndergroundEnteringHeightRef = useRef(sUndergroundEnteringHeight);
    useEffect(() => {
        sUndergroundEnteringHeightRef.current = sUndergroundEnteringHeight;
    }, [sUndergroundEnteringHeight]);

    const [sUndergroundRef, { height: sUndergroundHeight }] = useMeasure<HTMLDivElement>();
    const sUndergroundHeightRef = useRef(sUndergroundHeight);
    useEffect(() => {
        sUndergroundHeightRef.current = sUndergroundHeight;
    }, [sUndergroundHeight]);

    const [introStyles, introStylesApi] = useSpring(() => ({
        opacity: 0,
    }));

    const [routeIntroActive, setRouteIntroActive] = useState(false);
    const [routeIntroStyles, routeIntroStylesApi] = useSpring(() => ({
        y: 10,
        opacity: 0,
    }));

    const [creditsStyles, creditsStylesApi] = useSpring(() => ({
        opacity: 0,
    }));
    const [creditsProgress, setCreditsProgress] = useState(0);

    const progressUpdate = useRef(false);
    const updateProgress = useCallback(
        (section: SectionType, value: number) => {
            let pMainIntro = 0;
            let pEnterRoute = 0;
            let pRoute = 0;
            let pLeaving = 0;
            let pStaticContent = 0;
            let pGround = 0;
            let pUndergroundEntering = 0;
            let pUnderground = 0;

            if (section === 'mainIntro') {
                pMainIntro = value;
            } else if (section === 'enterRoute') {
                pMainIntro = 100;
                pEnterRoute = value;
            } else if (section === 'route') {
                pMainIntro = 100;
                pEnterRoute = 100;
                pRoute = value;
            } else if (section === 'landLeaving') {
                pMainIntro = 100;
                pEnterRoute = 100;
                pRoute = 100;
                pLeaving = value;
            } else if (section === 'staticContent') {
                pMainIntro = 100;
                pEnterRoute = 100;
                pRoute = 100;
                pLeaving = 100;
                pStaticContent = value;
            } else if (section === 'ground') {
                pMainIntro = 100;
                pEnterRoute = 100;
                pRoute = 100;
                pLeaving = 100;
                pStaticContent = 100;
                pGround = value;
            } else if (section === 'undergroundEntering') {
                pMainIntro = 100;
                pEnterRoute = 100;
                pRoute = 100;
                pLeaving = 100;
                pStaticContent = 100;
                pGround = 100;
                pUndergroundEntering = value;
            } else if (section === 'underground') {
                pMainIntro = 100;
                pEnterRoute = 100;
                pRoute = 100;
                pLeaving = 100;
                pStaticContent = 100;
                pGround = 100;
                pUndergroundEntering = 100;
                pUnderground = value;
            }

            if (section === 'mainIntro') {
                if (!progressUpdate.current) {
                    window.setTimeout(() => {
                        if (window.scrollY <= sMainIntroHeightRef.current) {
                            introStylesApi.start({
                                opacity: 1,
                            });
                        }
                    }, 700);
                } else {
                    introStylesApi.start({
                        opacity: 1,
                    });
                }
            } else {
                introStylesApi.start({
                    opacity: 0,
                });
            }

            if (section === 'enterRoute' || section === 'route') {
                setRouteIntroActive(true);
            } else {
                setRouteIntroActive(false);
            }

            if (section === 'route') {
                routeIntroStylesApi.start({
                    opacity: 1,
                    y: 0,
                });
            } else {
                routeIntroStylesApi.start({
                    opacity: 0,
                    y: 10,
                });
            }

            if (section === 'underground') {
                creditsStylesApi.start({
                    opacity: 1,
                    immediate: false,
                });
            } else {
                creditsStylesApi.start({
                    opacity: 0,
                    immediate: true,
                });
            }
            setCreditsProgress(pUnderground);

            onMainIntroProgressUpdate(pMainIntro);
            onEnterRouteProgressUpdate(pEnterRoute);
            onRouteProgressUpdate(pRoute);
            onLeavingProgressUpdate(pLeaving);
            onStaticContentProgress(pStaticContent);
            onGroundProgress(pGround);
            onUndergroundEnteringProgress(pUndergroundEntering);
            onUndergroundProgress(pUnderground);

            progressUpdate.current = true;
        },
        [
            onMainIntroProgressUpdate,
            onEnterRouteProgressUpdate,
            onRouteProgressUpdate,
            onLeavingProgressUpdate,
            onStaticContentProgress,
            onGroundProgress,
            onUndergroundEnteringProgress,
            onUndergroundProgress,
            introStylesApi,
            routeIntroStylesApi,
            creditsStylesApi,
        ],
    );

    useScroll({
        onChange(result) {
            const scrollY = result.value.scrollY;

            if (rootHeightRef.current > 0) {
                const scrollProgress = map(scrollY, 0, rootHeightRef.current, 0, 100);
                onProgressUpdate(scrollProgress);
            }

            const s1End = sMainIntroHeightRef.current;
            const s2End = s1End + sEnterRouteHeightRef.current;
            const s3End = s2End + sRouteHeightRef.current;
            const s4End = s3End + sLeavingHeightRef.current;
            const s5End = s4End + sStaticHeightRef.current + groundOffsetBefore;
            const s6End = s5End + sGroundHeightRef.current + groundOffsetAfter;
            const s7End = s6End + sUndergroundEnteringHeightRef.current;
            const s8End = s7End + sUndergroundHeightRef.current;

            if (scrollY <= s1End) {
                const s1progress = scrollY === 0 ? 0 : map(scrollY, 0, s1End, 0, 100);
                updateProgress('mainIntro', s1progress);
            } else if (scrollY <= s2End) {
                const s2progress = map(scrollY, s1End, s2End, 0, 100);
                updateProgress('enterRoute', s2progress);
            } else if (scrollY <= s3End) {
                const s3progress = map(scrollY, s2End, s3End, 0, 100);
                updateProgress('route', s3progress);
            } else if (scrollY <= s4End) {
                setRouteIntroActive(true);
                const section4progress = map(scrollY, s3End, s4End, 0, 100);
                updateProgress('landLeaving', section4progress);
            } else if (scrollY <= s5End) {
                const section5progress = map(scrollY, s4End, s5End, 0, 100);
                updateProgress('staticContent', section5progress);
            } else if (scrollY <= s6End) {
                const section5progress = map(scrollY, s5End, s6End, 0, 100);
                updateProgress('ground', section5progress);
            } else if (scrollY <= s7End) {
                const section6progress = map(scrollY, s6End, s7End, 0, 100);
                updateProgress('undergroundEntering', section6progress);
            } else if (scrollY <= s8End) {
                const section7progress = map(scrollY, s7End, s8End, 0, 100);
                updateProgress('underground', section7progress);
            } else {
                updateProgress('underground', 100);
            }
        },
        immediate: true,
        default: {
            immediate: true,
        },
    });

    return (
        <div ref={rootRef} className={classnames(styles.main, className)}>
            {displayScroll && <div className={styles.scroll}>SCROLL</div>}

            {/* section 1 event intro */}
            <Element name="section-about">
                <animated.section
                    className={styles.intro}
                    style={{
                        opacity: introStyles.opacity,
                    }}
                >
                    這是一座萬物共「聲」的奇妙島嶼，在這裡，大地的呼吸、萬物的吟唱、人們的歌謠，共同構成了這座「活」的聲音博物館，述說著時代的故事、歷史的秘密。
                    <br />
                    <br />
                    踏上島嶼後，請打開「耳朵」認識不同年代的恆春風華，用手機解任務、集圖鑑，用聲音突破年代的桎梏、跳脫人與人之間減少對話的高牆。
                </animated.section>
                <section ref={sMainIntroRef}>
                    <section className={styles.emptySection} />
                    <section className={styles.emptySection} />
                </section>
            </Element>

            {/* section 2 route intro */}
            <section ref={sEnterRouteRef}>
                <section className={styles.emptySection} />
                <section className={styles.emptySection} />
                <section className={styles.emptySection} />
                <section className={styles.emptySection} />
            </section>
            <animated.section
                className={styles.routeIntro}
                style={{
                    opacity: routeIntroStyles.opacity,
                    pointerEvents: routeIntroActive ? 'auto' : 'none',
                }}
            >
                <RouteIntro route={activeRoute} onClickMore={(key) => navigate(key)} />
            </animated.section>

            {/* section 3 route intro stop */}

            <section ref={sRouteRef}>
                <section className={styles.emptySection} />
                <section className={styles.emptySection} />
                <Element name="section-route">
                    <section className={styles.emptySection} />
                    <section className={styles.emptySection} />
                </Element>
            </section>

            {/* section 4 leaving */}
            <section ref={sLeavingRef}>
                <section className={styles.emptySection} />
                <section className={styles.emptySection} />
            </section>

            {/* section 5 game intro */}
            <section ref={sStaticRef}>
                <Element name="section-howto">
                    <SectionHowTo className={styles.sectionHowTo} />
                </Element>

                <Element name="section-purchase">
                    <SectionPurchase
                        className={styles.sectionPurchase}
                        onClickMore={() => {
                            navigate('/faq#purchase');
                        }}
                    />
                </Element>

                <Element name="section-faq">
                    <SectionFaq
                        className={styles.sectionFaq}
                        onClickMore={() => {
                            navigate('/faq');
                        }}
                    />
                </Element>
            </section>

            {/* section 6 ground */}
            <section ref={sGroundRef}>
                <Element name="section-contact">
                    <section className={styles.sectionGround}>
                        <SectionContact />
                    </section>
                </Element>
            </section>

            {/* section 7 underground entering */}
            <section ref={sUndergroundEnteringRef}>
                <section className={styles.emptySection} />
            </section>

            {/* section 8 underground */}
            <Element name="section-team">
                <section ref={sUndergroundRef} className={styles.team}>
                    {/* <animated.section style={creditsStyles}>
                        <SectionCredits progress={creditsProgress} />
                    </animated.section>
                    {Array.from({ length: Math.ceil(creditsData.length * 1.5) }).map((_, index) => (
                        <section key={index} className={styles.emptySection} />
                    ))} */}
                    <StaticCredits />
                </section>
            </Element>
        </div>
    );
};

export default Main;
