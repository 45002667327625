import classnames from 'classnames';
import { useState } from 'react';

import LandSlider from '@/components/organisms/LandSlider';
import RouteIntro from '@/components/molecules/RouteIntro';

import styles from './index.module.css';

export interface StaticRouteIntroProp {
    className?: string;
    onClickDetail?: (routeKey: string) => void;
}

const StaticRouteIntro: React.FC<StaticRouteIntroProp> = ({
    className,
    onClickDetail = () => {},
}) => {
    const [activeLand, setActiveLand] = useState<1 | 2 | 3>(1);

    return (
        <div className={classnames(styles.staticRouteIntro, className)}>
            <LandSlider activeLand={activeLand} onChange={(landId) => setActiveLand(landId)} />
            <RouteIntro route={activeLand} onClickMore={(key) => onClickDetail(key)} />
        </div>
    );
};

export default StaticRouteIntro;
