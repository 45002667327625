import classnames from 'classnames';
import Button from '@/components/atoms/Button';

import styles from './index.module.css';

export interface EnterProp {
    className?: string;
    onEnter?: () => void;
}

const Enter: React.FC<EnterProp> = ({ className, onEnter = () => {} }) => (
    <div className={classnames(styles.enter, className)}>
        <img className={styles.title} src="/images/title.png" alt="title" />
        <div className={styles.subtitle}>耳朵裡的恆春半島</div>
        <Button className={styles.btn} onClick={onEnter} hoverType="shake">
            ENTER
        </Button>
    </div>
);

export default Enter;
