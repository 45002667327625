import { useState, useEffect } from 'react';
import { useWindowSize as useWindowSizeRaw } from 'react-use';

const useWindowSize = () => {
    const { width: winWidthRaw, height: winHeightRaw } = useWindowSizeRaw(100, 100);

    const [winWidth, setWinWidth] = useState(100);
    const [winHeight, setWinHeight] = useState(100);

    useEffect(() => {
        setWinWidth(winWidthRaw);
    }, [winWidthRaw]);

    useEffect(() => {
        setWinHeight(winHeightRaw);
    }, [winHeightRaw]);

    return {
        winWidth,
        winHeight,
    };
};

export default useWindowSize;
