import classnames from 'classnames';
import { createBreakpoint } from 'react-use';
import { LINE_OFFICIAL_URL } from '@/utils/links';

import Countdown from '@/components/molecules/Countdown';

import styles from './index.module.css';

export interface CountdownMainSectionProp {
    className?: string;
}

const useBreakpoint = createBreakpoint({ XL: 1280, L: 380, S: 300 });

const CountdownMainSection: React.FC<CountdownMainSectionProp> = ({ className }) => {
    const breakpoint = useBreakpoint();

    return (
        <div className={classnames(styles.countdownMainSection, className)}>
            <img
                className={styles.title}
                src="/images/title.png"
                alt="title"
                width={190}
                height={55}
            />
            <div className={styles.intro}>
                「共聲島」為恆春半島首個戶外實境數位聲音體驗，
                {breakpoint !== 'S' && <br />}
                旅人只需攜帶手機與耳機，即可用「雙耳」穿越時空、
                {breakpoint !== 'S' && <br />}
                探索古城，聆聽豐富的自然景觀、歷史、文化及故事。
                {breakpoint !== 'S' && (
                    <>
                        <br />
                        <br />
                    </>
                )}
                透過手機系統解謎、闖關、與當地店家互動，
                {breakpoint !== 'S' && <br />}
                享受遊戲樂趣、交流故事。
            </div>

            <div className={styles.countdownGroup}>
                <div className={styles.label}>登陸倒數</div>
                <Countdown year={2024} month={6} day={1} hour={0} minute={0} />
            </div>

            <a href={LINE_OFFICIAL_URL} target="_blank" rel="noopener noreferrer">
                <div className={styles.btn}>搶先登陸共聲島</div>
            </a>
        </div>
    );
};

export default CountdownMainSection;
