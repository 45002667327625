import classnames from 'classnames';
import { useSpring, animated } from '@react-spring/web';
import { IG_URL, SHOP_URL, LINE_OFFICIAL_URL } from '@/utils/links';
import parse, { domToReact } from 'html-react-parser';
import { useMeasure } from 'react-use';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

import type { DOMNode, HTMLReactParserOptions } from 'html-react-parser';

import styles from './index.module.css';

export interface DrawerItemProp {
    className?: string;
    open?: boolean;
    title: string;
    content: string;
    onToggle?: (open: boolean) => void;
    onClickItem?: (itemId: number) => void;
}

const parseOptionsCurry: (onClickItem?: (itemId: number) => void) => HTMLReactParserOptions = (
    onClickItem?: (itemId: number) => void,
) => ({
    replace(domNode: DOMNode) {
        /* @ts-ignore */
        if (domNode.name === 'a') {
            // @ts-ignore
            if (domNode.attribs) {
                // @ts-ignore
                if (domNode.attribs.href === 'SHOP_URL') {
                    return (
                        <a href={SHOP_URL} target="_blank" rel="noreferrer noopener">
                            {/* @ts-ignore */}
                            {domToReact(domNode.children, parseOptionsCurry)}
                        </a>
                    );

                    // @ts-ignore
                } else if (domNode.attribs.href === 'IG_URL') {
                    return (
                        <a href={IG_URL} target="_blank" rel="noreferrer noopener">
                            {/* @ts-ignore */}
                            {domToReact(domNode.children, parseOptionsCurry)}
                        </a>
                    );

                    // @ts-ignore
                } else if (domNode.attribs.href === 'LINE_URL') {
                    return (
                        <a href={LINE_OFFICIAL_URL} target="_blank" rel="noreferrer noopener">
                            {/* @ts-ignore */}
                            {domToReact(domNode.children, parseOptionsCurry)}
                        </a>
                    );
                }

                return (
                    // @ts-ignore
                    <a href={domNode.attribs.href} target="_blank" rel="noreferrer noopener">
                        {/* @ts-ignore */}
                        {domToReact(domNode.children, parseOptionsCurry)}
                    </a>
                );
            }
        }

        // @ts-ignore
        if (domNode.name === 'em') {
            // @ts-ignore
            const itemId = domNode.children[0].data.replace('Q', '');

            return (
                // @ts-ignore
                <span className={styles.itemLink} onClick={() => onClickItem(parseInt(itemId, 10))}>
                    {/* @ts-ignore */}
                    {domToReact(domNode.children, parseOptionsCurry)}
                </span>
            );
        }
    },
});

const DrawerItem: React.FC<DrawerItemProp> = ({
    className,
    open,
    title,
    content,
    onToggle = () => {},
    onClickItem = () => {},
}) => {
    const [contentRef, { height }] = useMeasure<HTMLDivElement>();
    const { containerHeight } = useSpring({
        containerHeight: open ? height : 0,
    });

    return (
        <div className={classnames(styles.drawerItem, className)}>
            <div className={styles.label} onClick={() => onToggle(!open)}>
                <div className={styles.title}>{title}</div>
                <div className={classnames(styles.arrow, open && styles.open)}>
                    <FontAwesomeIcon icon={faChevronDown} />
                </div>
            </div>
            <animated.div className={styles.container} style={{ height: containerHeight }}>
                <div ref={contentRef} className={styles.contentWrapper}>
                    <div className={styles.content}>
                        {parse(content, parseOptionsCurry(onClickItem))}
                    </div>
                </div>
            </animated.div>
        </div>
    );
};

export default DrawerItem;
