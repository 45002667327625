import classnames from 'classnames';
import { LINE_OFFICIAL_URL } from '@/utils/links';

import Title from '@/components/atoms/Title';
import Button from '@/components/atoms/Button';

import styles from './index.module.css';

export interface SectionHowToProp {
    className?: string;
}

const SectionHowTo: React.FC<SectionHowToProp> = ({ className }) => {
    return (
        <div className={classnames(styles.gameIntro, className)}>
            <Title>怎麼玩</Title>
            <div className={styles.itemWrapper}>
                <div className={styles.item}>
                    <div className={styles.icon}>
                        <img src="/images/intro-1.png" alt="intro" />
                    </div>
                    <div className={styles.label}>打開 LINE 走入街區</div>
                    <div className={styles.context}>
                        加入「共聲島」官方 LINE 帳號@peninsoundla，選擇欲參加的體驗路線
                    </div>
                </div>
                <div className={styles.item}>
                    <div className={styles.icon}>
                        <img src="/images/intro-2.png" alt="intro" />
                    </div>
                    <div className={styles.label}>享受聲音故事</div>
                    <div className={styles.context}>
                        戴上耳機，透過「共聲島」LINE 聽到故事、並依照相關指示說明完成任務
                    </div>
                </div>
                <div className={styles.item}>
                    <div className={styles.icon}>
                        <img src="/images/intro-3.png" alt="intro" />
                    </div>
                    <div className={styles.label}>互動並收集圖鑑</div>
                    <div className={styles.context}>
                        每完成一個任務就能獲得一個圖鑑，參加的任務越多，就可以得到更多圖鑑喔！
                    </div>
                </div>
            </div>
            <a href={LINE_OFFICIAL_URL} target="_blank" rel="noopener noreferrer">
                <Button className={styles.btn}>馬上體驗共聲島</Button>
            </a>
        </div>
    );
};

export default SectionHowTo;
