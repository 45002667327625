import { v4 as uuidv4 } from 'uuid';

interface Role {
    title: string;
    members: string[];
}

interface Team {
    id?: string;
    type?: string;
    title: string;
    note?: string;
    roles: Role[];
    members: string[];
}

export const creditsRaw: Team[] = [
    {
        title: '聲音演員',
        // note: '按姓氏筆劃順序排列',
        roles: [],
        members: [
            'Summer Hsu（謝徐鳳玉）',
            '小嫻（黃瑜嫻）',
            '余楊心平',
            '吳明蕙',
            '松田強尼',
            '林惠琪',
            '高李正姬',
            '莫子儀',
            '曾志遠',
            '駱若瑀',
            '謝宜均',
            '魏雋展',
        ],
    },
    {
        title: '製作團隊',
        roles: [
            {
                title: '出品',
                members: ['火箭人實驗室'],
            },
            {
                title: '監製',
                members: ['張彥頡'],
            },
            {
                title: '製作人',
                members: ['黃雯'],
            },
            {
                title: '數位開發經理',
                members: ['駱若瑀'],
            },
            {
                title: '內容製作經理',
                members: ['Dub Lau', '（劉峻佑）'],
            },
            {
                title: '前期企畫、行政協力',
                members: ['朱靖閎'],
            },
        ],
        members: [],
    },
    {
        title: '',
        roles: [
            {
                title: '導演',
                members: ['曾志遠'],
            },
            {
                title: '編劇',
                members: ['胡錦筵'],
            },
            {
                title: '助理編劇',
                members: ['黃軒萱', '張家瑋'],
            },
            {
                title: '聲音採集、設計',
                members: ['余楊心平'],
            },
            {
                title: '母帶後期處理',
                members: ['林容齊'],
            },
        ],
        members: [],
    },
    {
        title: '',
        roles: [
            {
                title: '網頁設計開發',
                members: ['蕭宇彤'],
            },
            {
                title: 'UI 設計',
                members: ['呂婉柔', '陳冠穎', '蕭宇彤'],
            },
            {
                title: 'UX 設計',
                members: ['蕭宇彤'],
            },
            {
                title: '遊戲體驗設計',
                members: ['駱若瑀'],
            },
        ],
        members: [],
    },
    {
        title: '',
        roles: [
            {
                title: '行銷統籌',
                members: ['林惠琪'],
            },
            {
                title: '前期行銷企畫、執行',
                members: ['林采葳', '施曼妮'],
            },
            {
                title: '商業開發、媒體執行',
                members: ['王胤璇'],
            },
            {
                title: '主視覺設計',
                members: ['呂婉柔'],
            },
            {
                title: '平面設計執行',
                members: ['蕭宇珊'],
            },
            {
                title: '平面攝影',
                members: ['朱靖閎'],
            },
            {
                title: '動態影像',
                members: ['王稔誼', '王俊麟', '施達樂'],
            },
            {
                title: '印刷製作',
                members: ['苑美海報輸出印刷坊'],
            },
        ],
        members: [],
    },
    {
        title: '',
        roles: [
            {
                title: '會計',
                members: ['鄭茲文'],
            },
            {
                title: '出納',
                members: ['余楊心平'],
            },
            {
                title: '客服執行',
                members: ['陳佩君'],
            },
        ],
        members: [],
    },

    {
        title: '前期開發',
        // note: '按姓氏筆劃順序排列',
        roles: [
            {
                title: '田野調查參與人員',
                members: [
                    '山羊',
                    '王運金',
                    '余楊心平',
                    '吳威德',
                    '林秋月',
                    '林博文',
                    '林瓊瑤',
                    '高李正姬',
                    '張皓鈞',
                    '莊浩旭',
                    '莊期文',
                    '許秀美',
                    '楊國生',
                    '潘甄華',
                    '謝徐鳳玉',
                ],
            },
        ],
        members: [],
    },
    {
        title: '',
        // note: '按姓氏筆劃順序排列',
        roles: [
            {
                title: '地方交流工作坊講師',
                members: ['胡錦筵', '周書毅', '張彥頡', '張麒威'],
            },
            {
                title: '《戀の時光町》實體導覽規畫',
                members: ['林瓊瑤'],
            },
            {
                title: '導覽解說員培訓講師',
                members: ['李易璇（花花）', '林瓊瑤', '魏兆廷'],
            },
            {
                title: '讀劇演員',
                members: ['周政憲', '黃芊涵'],
            },
            {
                title: '顧問',
                members: ['林瓊瑤', '林博文', '陳威廷'],
            },
        ],
        members: [],
    },
    {
        title: '導覽解說員',
        // note: '按姓氏筆劃順序排列',
        roles: [],
        members: [
            '王晟祐',
            '李玉華',
            '林欣怡',
            '林俐貞',
            '邱彚珊',
            '翁薇涵',
            '張潔晰',
            '陳佩君',
            '陳思廷',
            '陳瑋',
            '黃慈蕙',
            '廖文莉',
            '潘麒亘',
            '鄭茲文',
            '蕭辰卉',
            '謝佳珊',
        ],
    },
    {
        title: '特別感謝',
        // note: '按姓氏筆劃順序排列',
        roles: [],
        members: [
            '朱奕安',
            '吳素如',
            '吳翌睿',
            '吳瑞珊',
            '李文豪',
            '李玉英',
            '杜妍',
            '杜侑哲',
            '杜騫',
            '周書毅',
            '林博文',
            '林瓊瑤',
            '唐書萍',
            '孫琬婷',
            '徐美琴',
            '張中彥',
            '張淑雅',
            '張麒威',
            '莊浩旭',
            '陳佩君',
            '陳美琪',
            '陳凱莉',
            '楊家豪',
            '董浩翔',
            '詹宗樺',
            '劉子齊',
            '潘宏榮',
            '潘甄華',
            '鄭毓玄',
            '黎任杰',
            '藍學明',
        ],
    },
    {
        title: '合作串連店家',
        // note: '按筆劃順序排列',
        roles: [],
        members: [
            'LAUNCHER CLUB CAFE',
            'Step Up',
            '一芳海苔醬',
            '三山國王廟',
            '小間珈琲',
            '小黃間CAFExSPORTSxCUB',
            '木屐王',
            '半島。肆集',
            '古來嬉',
            '玉珍香餅店',
            '伯虎在二樓',
            '協振昌裁縫店',
            '咖啡白',
            '東昇電料行',
            '波波廚房',
            '金元盛銀樓',
            '金像咖啡',
            '阿伯綠豆蒜',
            '南門+緣',
            '春酩',
            '祥倫鐘錶行',
            '嗜。咖啡',
            '新成興布行',
            '榮輪腳車行',
            '熱情波浪',
            '燈亮小餐館',
            '灣灣小舖',
        ],
    },
];

const credits = creditsRaw.map((credit) => {
    const cId = uuidv4();

    let type = '';

    if (credit.title === '製作團隊') {
        type = 'produceTeam';
    } else if (credit.title === '聲音演員') {
        type = 'soundActor';
    } else if (credit.title === '前期開發') {
        type = 'research';
    } else if (credit.title === '導覽解說員') {
        type = 'tourGuide';
    } else if (credit.title === '合作串連店家') {
        type = 'store';
    }

    return {
        id: cId,
        type,
        ...credit,
    };
});

export default credits;
