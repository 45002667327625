import classnames from 'classnames';

import styles from './index.module.css';

export interface ButtonProp {
    className?: string;
    hoverType?: 'none' | 'shake' | 'color';
    disabled?: boolean;
    children: React.ReactNode;
    onClick?: () => void;
}

const Button: React.FC<ButtonProp> = ({
    className,
    hoverType = 'none',
    disabled,
    children,
    onClick = () => {},
}) => (
    <div
        className={classnames(
            styles.button,
            styles[hoverType],
            disabled && styles.disabled,
            className,
        )}
        onClick={() => {
            if (!disabled) {
                onClick();
            }
        }}
    >
        {children}
    </div>
);

export default Button;
