import classnames from 'classnames';
import { useCallback, useEffect } from 'react';
import { useList } from 'react-use';
import { random } from '@/utils/math';

import StarCompnent from '@/components/atoms/Star';

import styles from './index.module.css';

export interface StarLayerProp {
    className?: string;
    num: number;
}

interface Star {
    id: number;
    x: number;
    y: number;
    scale: number;
    angle: number;
}

const StarLayer: React.FC<StarLayerProp> = ({ className, num = 10 }) => {
    const [randomedStarsLayer1, { push: pushLayer1, clear: clearLayer1 }] = useList<Star>([]);

    const setupRandomedStars = useCallback(() => {
        clearLayer1();

        Array.from({ length: num }).forEach((_, index) => {
            const xMin = 0;
            const xMax = 100;
            const yMin = 0;
            const yMax = 100;
            const scaleMin = 0.3;
            const scaleMax = 0.8;

            pushLayer1({
                id: index,
                x: random(xMin, xMax),
                y: random(yMin, yMax),
                scale: random(scaleMin, scaleMax),
                angle: random(0, 360),
            });
        });
    }, [num, pushLayer1, clearLayer1]);

    useEffect(() => {
        setupRandomedStars();
    }, [setupRandomedStars]);

    return (
        <div className={classnames(styles.starLayer, className)}>
            {randomedStarsLayer1.map(({ id, x, y, scale, angle }, index) => (
                <StarCompnent
                    key={id}
                    x={x}
                    y={y}
                    scale={scale}
                    angle={angle}
                    twinkling={index % 2 === 0}
                />
            ))}
        </div>
    );
};

export default StarLayer;
