import classnames from 'classnames';
import { EMAIL_URL, IG_URL, LINE_OFFICIAL_URL } from '@/utils/links';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Title from '@/components/atoms/Title';
import { faSquareInstagram, faLine } from '@fortawesome/free-brands-svg-icons';

import styles from './index.module.css';

export interface SectionContactProp {
    className?: string;
}

const Contact: React.FC<SectionContactProp> = ({ className }) => {
    return (
        <div className={classnames(styles.contact, className)}>
            <Title className={styles.title}>聯絡我們</Title>
            <section className={styles.content}>
                <p>有任何問題，歡迎來信詢問 / 私訊粉專</p>
                <p>{EMAIL_URL}</p>
                <div className={styles.iconGroup}>
                    <a
                        className={styles.icon}
                        href={IG_URL}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <FontAwesomeIcon icon={faSquareInstagram} size="xl" />
                    </a>
                    <a
                        className={styles.icon}
                        href={LINE_OFFICIAL_URL}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <FontAwesomeIcon icon={faLine} size="xl" />
                    </a>
                </div>
            </section>
        </div>
    );
};

export default Contact;
