import classnames from 'classnames';
import { Stack } from '@mui/material';
import { useWindowSize } from '@/utils/hooks';

import Title from '@/components/atoms/Title';

import credits from '@/utils/credits';

import styles from './index.module.css';

export interface StaticCreditsProp {
    className?: string;
}

const rowNum = 3;

const StaticCredits: React.FC<StaticCreditsProp> = ({ className }) => {
    const { winWidth } = useWindowSize();

    return (
        <Stack className={classnames(styles.staticCredits, className)} spacing={1}>
            <div>
                <Title className={styles.sectionTitle}>團隊介紹</Title>
                <div className={styles.note}>（按筆劃順序排列）</div>
            </div>
            {credits.map((data, index) => {
                return (
                    <Stack
                        key={data.id}
                        className={classnames(styles.group)}
                        direction="column"
                        spacing={3}
                        justifyContent="center"
                        alignItems="center"
                    >
                        {data.title && (
                            <Stack
                                className={classnames(
                                    styles.titleWrapper,
                                    data.type === 'soundActor' && styles.noPadding,
                                )}
                                spacing={1}
                            >
                                <div className={styles.title}>{data.title}</div>

                                {data.note && <div className={styles.note}>（{data.note}）</div>}
                            </Stack>
                        )}

                        {data.roles.length > 0 && (
                            <Stack
                                className={classnames(
                                    styles.roleContainer,
                                    data.type && styles[data.type],
                                )}
                                spacing={1}
                                justifyContent="center"
                                alignContent="center"
                            >
                                {data.roles.map((role) => (
                                    <Stack
                                        key={role.title}
                                        className={classnames(
                                            styles.role,
                                            styles.inline,
                                            // role.members.length <= 5 && styles.inline,
                                        )}
                                        // direction={role.members.length <= 5 ? 'row' : 'column'}
                                        direction={'row'}
                                        spacing={2}
                                    >
                                        <div
                                            className={classnames(
                                                styles.roleTitle,
                                                styles.inline,
                                                // role.members.length <= 5 && styles.inline,
                                            )}
                                        >
                                            {role.title}
                                        </div>

                                        <Stack
                                            className={styles.roleMemberContainer}
                                            direction="row"
                                            flexWrap="wrap"
                                            justifyContent={
                                                undefined
                                                // role.members.length <= 5 ? undefined : 'center'
                                            }
                                            // spacing={1}
                                        >
                                            {role.members.map((member) => (
                                                <div
                                                    key={member}
                                                    className={styles.roleMember}
                                                    style={{
                                                        paddingRight: 8,
                                                    }}
                                                >
                                                    {member}
                                                </div>
                                            ))}
                                        </Stack>
                                    </Stack>
                                ))}
                            </Stack>
                        )}
                        {data.members.length > 0 && (
                            <Stack
                                className={classnames(
                                    styles.memberContainer,
                                    data.type && styles[data.type],
                                )}
                                direction={
                                    data.type === 'store' && winWidth <= 800 ? 'column' : 'row'
                                }
                                alignItems={
                                    data.type === 'store' && winWidth <= 800 ? 'center' : undefined
                                }
                                justifyContent={
                                    data.members.length > 12 ? 'space-between' : 'center'
                                }
                            >
                                {data.members.length <= 12 && (
                                    <Stack className={styles.memberRow}>
                                        {data.members.map((member) => (
                                            <div key={member} className={styles.member}>
                                                {member}
                                            </div>
                                        ))}
                                    </Stack>
                                )}

                                {data.members.length > 12 && (
                                    <>
                                        <Stack className={styles.memberRow}>
                                            {data.members
                                                .slice(0, Math.ceil(data.members.length / rowNum))
                                                .map((member) => (
                                                    <div
                                                        key={member}
                                                        className={classnames(
                                                            styles.member,
                                                            styles.multirow,
                                                        )}
                                                    >
                                                        {member}
                                                    </div>
                                                ))}
                                        </Stack>
                                        <Stack className={styles.memberRow}>
                                            {data.members
                                                .slice(
                                                    Math.ceil(data.members.length / rowNum),
                                                    Math.ceil(data.members.length / rowNum) * 2,
                                                )
                                                .map((member) => (
                                                    <div
                                                        key={member}
                                                        className={classnames(
                                                            styles.member,
                                                            styles.multirow,
                                                        )}
                                                    >
                                                        {member}
                                                    </div>
                                                ))}
                                        </Stack>
                                        {rowNum === 3 && (
                                            <Stack className={styles.memberRow}>
                                                {data.members
                                                    .slice(
                                                        Math.ceil(data.members.length / rowNum) * 2,
                                                        data.members.length,
                                                    )
                                                    .map((member) => (
                                                        <div
                                                            key={member}
                                                            className={classnames(
                                                                styles.member,
                                                                styles.multirow,
                                                            )}
                                                        >
                                                            {member}
                                                        </div>
                                                    ))}
                                            </Stack>
                                        )}
                                    </>
                                )}
                            </Stack>
                        )}
                        {index === credits.length - 1 && (
                            <div className={styles.end}>
                                本計劃「由文化部推動地方創生文化環境營造補助計畫經費支持」
                            </div>
                        )}
                    </Stack>
                );
            })}
        </Stack>
    );
};

export default StaticCredits;
